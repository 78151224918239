function decorateMethod(originalMethod) {
  let previousArgs = [];
  let originalFnWasCalledLeastAtOnce = false;
  let pureValue;
  return function mgPureMethodPatched(...args) {
    const isPure = originalFnWasCalledLeastAtOnce && previousArgs.length === args.length && args.every((arg, index) => arg === previousArgs[index]);
    if (isPure) {
      return pureValue;
    }
    previousArgs = args;
    pureValue = originalMethod.apply(this, args);
    originalFnWasCalledLeastAtOnce = true;
    return pureValue;
  };
}
function decorateGetter(originalGetter, propertyKey, enumerable = true) {
  return function mgPureGetterPatched() {
    const value = originalGetter.call(this);
    Object.defineProperty(this, propertyKey, {
      enumerable,
      value
    });
    return value;
  };
}
function MgPure(target, propertyKeyOrContext, descriptor) {
  if (typeof target === `function`) {
    const context = propertyKeyOrContext;
    if (context.kind === `getter`) {
      return decorateGetter(target, context.name);
    }
    if (context.kind === `method`) {
      return decorateMethod(target);
    }
    throw new Error('mgPure can only be used with functions or getters');
  }
  const {
    get,
    enumerable,
    value
  } = descriptor;
  const propertyKey = propertyKeyOrContext;
  if (get) {
    return {
      configurable: true,
      enumerable,
      get: decorateGetter(get, propertyKey, enumerable)
    };
  }
  if (typeof value !== `function`) {
    throw new Error('mgPure can only be used with functions or getters');
  }
  const original = value;
  return {
    configurable: true,
    enumerable,
    get() {
      let previousArgs = [];
      let originalFnWasCalledLeastAtOnce = false;
      let pureValue;
      const patched = (...args) => {
        const isPure = originalFnWasCalledLeastAtOnce && previousArgs.length === args.length && args.every((arg, index) => arg === previousArgs[index]);
        if (isPure) {
          return pureValue;
        }
        previousArgs = args;
        pureValue = original.apply(this, args);
        originalFnWasCalledLeastAtOnce = true;
        return pureValue;
      };
      Object.defineProperty(this, propertyKey, {
        configurable: true,
        value: patched
      });
      return patched;
    }
  };
}
function MgDebounce(timeout = 300) {
  let timeoutRef = undefined;
  return function (target, propertyKey, descriptor) {
    const original = descriptor.value;
    descriptor.value = function (...args) {
      clearTimeout(timeoutRef);
      timeoutRef = setTimeout(() => {
        original.apply(this, args);
      }, timeout);
    };
    return descriptor;
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { MgDebounce, MgPure };
